import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import EmbedYoutube from "../../components/EmbedYoutube";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";

export default function cooking(props) {
  return (
    <Layout>
      <SEO title="Backen" />

      <div
        className="fusion-column-wrapper"
        data-bg-url=""
        tw="flex flex-col justify-center text-center relative text-black"
      >
        <h1
          data-fontsize="54"
          data-lineheight="62.64px"
          tw="text-primary font-bold text-4xl md:text-6xl my-8"
        >
          BACKEN
        </h1>

        <div
          className=""
          tw="font-bold text-xl md:text-2xl px-4 "
          style={{ fontFamily: "Nunito Sans" }}
        >
          <h3 data-fontsize="22" data-lineheight="28.6px" tw="mb-4">
            Regelmäßige Backveranstaltungen sorgen für Frequenz und Gerätetest
            in Ihrem Haus.
          </h3>
          <h3 tw="mb-4 md:mb-24">Wir erledigen das für Sie.</h3>
        </div>
        <div className="" tw="flex justify-center mb-4 md:mb-24">
          <EmbedYoutube videoId="VOh7EvoBhvU" />
        </div>
        {/* picture */}
        <div className="imageframe-align-center" tw="mx-4 md:mx-8">
          <span
            style={{ width: "100%", maxWidth: "2000px" }}
            className="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
          >
            <StaticImage
              src="../../images/Backen-2-scaled.jpg"
              width="2246"
              height="2560"
              alt=""
              title="Cooking school"
            />
          </span>
        </div>
      </div>
    </Layout>
  );
}
